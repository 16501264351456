import {
    Alert,
    Button,
    Grid
}                              from "@mui/material";
import React, {useEffect}      from "react";
import CompanyMeasurementsBlock from "../components/blocks/CompanyMeasurementsBlock";
import {
    AuthToken,
    baseUrl
} from "../constants";
import {useGobesContext}       from "../context/GobesContextProvider";
import UpsertObjectModal       from "../modals/UpsertObjectModal";
import BronBoorOverview        from "./BronBoorOverview";
import CompanyDetailOverview   from "./CompanyDetailOverview";
import CompanyMeasurementOverview from "./CompanyMeasurementOverview";
import CompanyOverview         from "./CompanyOverview";
import ControlesOverview       from "./ControlesOverview";
import NotitiesOverview        from "./NotitiesOverview";
import PermitWellOverview from "./PermitWellOverview";
import SysteemGegevensOverview from "./SysteemGegevensOverview";
import logo from "../resources/logo.webp"
import Typography from '@mui/material/Typography';

const MainPage = () =>
{

    const {
        activePage, setActivePage,
        subPage, setSubPage,
        alertVisible, alertText,
        setAlertVisible,
    } = useGobesContext()

    useEffect(() =>
    {
        setAlertVisible(false)
    }, [subPage, activePage]);

    function onClickHome()
    {
        setActivePage(0)
    }

    function onClickBack()
    {
        //Decrement it by one to go back a page, disspears when activepage is
        if (activePage === 4)
        {
            setActivePage(2)
            return
        }
        if (activePage > 0)
        {
            setActivePage((activePage: number) => activePage - 1)
        }
    }
    const TopButtons = <Grid container justifyContent="center">
        <Grid item>
            <img width={150} src={logo}/>

            <Grid item>
                <Button variant={"contained"} className={"default-button"} onClick={onClickHome}>Home</Button>
                {activePage !== 0 && <Button variant={"contained"} className={"default-button"} onClick={onClickBack}>Terug</Button>}
                <Alert style={{
                    display: alertVisible
                             ? "flex"
                             : "none",
                }} severity="error" color={"warning"}>{alertText}</Alert>

            </Grid>
        </Grid>
    </Grid>

    const SelectionButtons = <Grid container className={"rm-container"} justifyContent={"flex-start"} flexDirection={"row"}>
        <Button onClick={() => setSubPage(0)} variant={subPage === 0
                                                       ? "outlined"
                                                       : "text"}>Systeemgegevens</Button>
        <Button onClick={() => setSubPage(1)} variant={subPage === 1
                                                       ? "outlined"
                                                       : "text"}>Controles</Button>
        <Button onClick={() => setSubPage(2)} variant={subPage === 2
                                                       ? "outlined"
                                                       : "text"}>Notities</Button>
        <div style={{paddingTop: 4}}>|</div>
        <Button onClick={() => setSubPage(3)} variant={subPage === 3
                                                       ? "outlined"
                                                       : "text"}>Meetgegevens</Button>
    </Grid>

    return (<>
        <html ig-base-url={baseUrl + "/ig"} ig-api-token={AuthToken}>
        <Grid>
            <Typography style={{position:"absolute", left: 5, top: 30}} variant="h2" gutterBottom>
                GOBES Administratie
            </Typography>
        </Grid>

        <Grid container direction={"column"}>
            {TopButtons}

            <Grid className={"rm-container"}>

                {activePage === 0 && <CompanyOverview/>}
                {activePage === 1 && <CompanyDetailOverview/>}

                {activePage === 2 && SelectionButtons}
                {activePage === 2 && subPage === 0 && <SysteemGegevensOverview/>}
                {activePage === 2 && subPage === 1 && <ControlesOverview/>}
                {activePage === 2 && subPage === 2 && <NotitiesOverview/>}
                {activePage === 2 && subPage === 3 && <CompanyMeasurementOverview/>}

                {activePage === 3 && subPage === 0 && <BronBoorOverview/>}
                {activePage === 4 && subPage === 0 && <PermitWellOverview/>}

            </Grid>
            <UpsertObjectModal/>

        </Grid>
        </html>

    </>)
}
export default MainPage