import PermitDetailBlock from "../components/blocks/PermitDetailBlock";
import PermitWellsBlock from "../components/blocks/PermitWellsBlock";

const PermitWellOverview = () => {

    return (<>
        <PermitDetailBlock/>
        <PermitWellsBlock/>
    </>)
}
export default PermitWellOverview