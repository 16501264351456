import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddBoorprofielCommand,
    Boorprofiel,
    BoorprofielDetail,
    UpdateBoorprofielCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {submitObject}    from "../util";

const BoorprofielForm = () =>
{
    const FormRef = useRef<any>()
    const {energyDetails, bronDetails, setModalVisible, DoRefresh, setAlertText,formData,companyDetails,setRefreshResource,setAlertVisible} = useGobesContext()

    useEffect(() =>
    {
        if (FormRef.current !== undefined)
        {
            FormRef.current.data = {
                ...formData,
                wellId: bronDetails.id
            }
        }
    }, []);

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, companyDetails?.id, AddBoorprofielCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Boorprofiel)

                }
                else
                {
                    submitObject(Data, companyDetails?.id, UpdateBoorprofielCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(BoorprofielDetail)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {formData === undefined
             ? "Boorprofiel toevoegen"
             : "Boorprofiel bewerken"}
        </Typography>
        {formData === undefined
            // @ts-ignore
         ? <ig-form ref={FormRef} form-id="AddBoorprofiel"/>
            // @ts-ignore
         : <ig-form ref={FormRef} form-id="UpdateBoorprofiel"/>}
        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default BoorprofielForm