import {
    Button,
    Grid,
    Popover,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useRef
}                        from "react";
import {
    Company,
    CompanyDetail,
    DeactivateCompanyCommand,
    Delete,
    IGStyle,
    UpdateCompany
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject}    from "../../util";

const CompanyBlock = () =>
{
    const {
        companyDetails, setModalVisible, setModalAction, setFormData,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText,
        setActivePage, setSubPage,setCompanyDetails,setDeleteCallback
    } = useGobesContext()


    const DeleteText = "Bedrijf Verwijderen"
    const RDRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case CompanyDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break

        }
        setRefreshResource("")
    }, [refreshResource]);

    function BedrijfBewerken()
    {
        setFormData(RDRef.current?.data)
        setModalVisible(true)
        setModalAction(UpdateCompany)
    }

    function DeleteObject()
    {
        submitObject({id: RDRef.current?.data.id}, companyDetails?.id, DeactivateCompanyCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })
        setCompanyDetails(undefined)
        setActivePage(0)
        setSubPage(0)
        setRefreshResource(Company)
    }

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200)
        {
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }
    const handleStartDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        setModalVisible(true)
        setModalAction(Delete)
        setDeleteCallback({delete: DeleteObject} )
    };


    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Bedrijf
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-detail lang="nl-NL" ref={RDRef} style={IGStyle} class={"ig-detail-remove-rows"} resource-detail-id="CompanyRD" resource-id={companyDetails?.company?.id}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={BedrijfBewerken}>Bedrijf bewerken </Button>
                            <Button variant={"contained"} onClick={handleStartDelete}>{DeleteText} </Button>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default CompanyBlock