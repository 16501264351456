import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddNoteCommand,
    Note,
    NoteDetail,
    UpdateNoteCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {submitObject}    from "../util";

const NotitieForm = () =>
{
    const FormRef = useRef<any>()
    const {energyDetails, setModalVisible, setAlertText ,formData,companyDetails,setRefreshResource,setAlertVisible} = useGobesContext()

    useEffect(() =>
    {

        if (FormRef.current !== undefined && energyDetails !== undefined)
        {
            FormRef.current.data = {
                ...formData,
                energySystemId: energyDetails.id

            }

        }
    }, []);

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, companyDetails?.id, AddNoteCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Note)

                }
                else
                {
                    submitObject(Data, companyDetails?.id, UpdateNoteCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(NoteDetail)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {formData === undefined
             ? "Notitie toevoegen"
             : "Notitie bewerken"}
        </Typography>
        {/*// @ts-ignore*/}
        {formData === undefined
            // @ts-ignore
         ? <ig-form class={"bigger-textfields"} ref={FormRef} form-id="AddNote"/>
            // @ts-ignore
         : <ig-form class={"bigger-textfields"} ref={FormRef} form-id="UpdateNote"/>}
        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default NotitieForm