import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddCadastralInfoCommand,
    Cadastral,
    CadastralDetail,
    UpdateCadastralInfoCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {submitObject}    from "../util";

const CadastralForm = () =>
{
    const { energyDetails, setModalVisible,formData,companyDetails,setRefreshResource,setAlertVisible, setAlertText } = useGobesContext()
    const FormRef = useRef<any>()

    useEffect(() =>
    {
        if (FormRef.current !== undefined && energyDetails !== undefined)
        {
            FormRef.current.data = {
                ...formData,
                energySystemId: energyDetails.id
            }
        }
    }, []);

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, companyDetails?.id, AddCadastralInfoCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Cadastral)

                }
                else
                {
                    submitObject(Data, companyDetails?.id, UpdateCadastralInfoCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(CadastralDetail)

                }
            }
        )

    }

    return (<>

            <Typography id="modal-modal-title" variant="h6" component="h2">
                {formData === undefined
                 ? "Kadastrale gegevens toevoegen "
                 : "Kadastrale gegevens bewerken"}
            </Typography>
            {/*// @ts-ignore*/}

            {/*// @ts-ignore*/}
            {formData === undefined
                // @ts-ignore
             ? <ig-form ref={FormRef} form-id="AddCadastralInfo"/>
                // @ts-ignore
             : <ig-form ref={FormRef} form-id="UpdateCadastralInfo"/>}

            <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
        </>
    )

}
export default CadastralForm